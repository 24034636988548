import React from "react";
import classNames from "classnames";

function Modal({ isOpen, onClose, children, className }) {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className={classNames("modal", className)}>
        <button className="close-button" onClick={onClose}>
          ✕
        </button>
        {children}
      </div>
    </div>
  );
}

export default Modal;
