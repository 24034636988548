import React from "react";

function ConfirmationPage({ confirmationData }) {
  return (
    <div className="confirmation-page" style={{ textAlign: 'center' }}>
      <h2><strong>Your Request Has Been Sent Successfully</strong></h2>
      <p>Your confirmation code is:</p>
      <h3>{confirmationData.confirmationCode}</h3>
      <a href={confirmationData.pdfUrl} target="_blank" rel="noopener noreferrer" download>
        <button style={{ padding: "0.5rem 1rem", backgroundColor: "#010E2D", color: "#fff", border: "none", cursor: "pointer" }}>
          Recipt {confirmationData.requestNumber} PDF
        </button>
      </a>
    </div>
  );
}

export default ConfirmationPage;
