import React from "react";
import Modal from "./Modal";

function ModalActivity({ isOpen, onClose, onDone }) {
  const [activityName, setActivityName] = React.useState("");
  const [members, setMembers] = React.useState([]);
  const [newMember, setNewMember] = React.useState("");

  const handleAddMember = () => {
    if (newMember && !members.includes(newMember)) {
      setMembers([...members, newMember]);
      setNewMember("");
    }
  };

  const handleRemoveMember = (member) => {
    setMembers(members.filter((m) => m !== member));
  };

  const handleDone = () => {
    onDone({ activityName, members });
    onClose();
  };

  if (!isOpen) return null;

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <h6>Create a Project</h6>
      <div className="form-group">
        <label>Project Name</label>
        <input
          type="text"
          value={activityName}
          onChange={(e) => setActivityName(e.target.value)}
          placeholder="Enter project name"
        />
      </div>
      <div className="form-group">
        <label>Add Members</label>
        <input type="email" value={newMember} onChange={(e) => setNewMember(e.target.value)} placeholder="Enter email" />
        <button className="dark" onClick={handleAddMember}>
          Add
        </button>
      </div>
      <div className="members-list">
        {members.map((member, index) => (
          <span key={index} className="member">
            {member}
            <button onClick={() => handleRemoveMember(member)}>✕</button>
          </span>
        ))}
      </div>
      <div className="modal-actions">
        <button className="outline" onClick={onClose}>
          Cancel
        </button>
        <button className="dark" onClick={handleDone}>
          Done
        </button>
      </div>
    </Modal>
  );
}

export default ModalActivity;
