import React, { useState } from "react";

function StepExistingData({ formData, updateFormData }) {
  const dataTypes = ["InSAR", "LiDAR", "Optical", "GPS", "Inclinometers", "Piezometers", "Other"];
  const defaultOpenDataSources = [
    "Dati Progetto IFFI",
    "Alluvioni Classi di Rischio (PGRA)",
    "Pluviometri",
    "Modello di elevazione digitale (DEM)",
    "Layer Classificazione Sismica",
    "Layer erosione costiera",
    "Aree a potenziale rischio significativo di alluvione (APSFR)",
    "Bacini idrografici principali e secondari",
    "Carta geologica d'Italia",
    "Cartografia di base - IGM 25.000",
    "PAI - Pericolosita' idrogeologica",
    "PAI - Rischio idrogeologico",
  ];
  const [newSource, setNewSource] = useState("");

  const handleCheckboxChange = (id) => {
    const updatedDataTypes = formData.dataTypes.includes(id)
      ? formData.dataTypes.filter((item) => item !== id)
      : [...formData.dataTypes, id];

    updateFormData("dataTypes", updatedDataTypes);
  };

  const handleOpenDataSourceChange = (source) => {
    const updatedSources = formData.openDataSources.includes(source)
      ? formData.openDataSources.filter((item) => item !== source)
      : [...formData.openDataSources, source];

    updateFormData("openDataSources", updatedSources);
  };

  const handleAddNewSource = () => {
    if (newSource.trim() && !formData.openDataSources.includes(newSource)) {
      updateFormData("openDataSources", [...formData.openDataSources, newSource]);
      setNewSource("");
    }
  };

  return (
    <div>
      <h3>Existing Data</h3>
      <div>
        <h6>Are there existing data for the area?</h6>
        <label>
          <input
            type="radio"
            name="existingData"
            value="yes"
            checked={formData.existingData === "yes"}
            onChange={() => updateFormData("existingData", "yes")}
          />
          Yes
        </label>
        <label>
          <input
            type="radio"
            name="existingData"
            value="no"
            checked={formData.existingData === "no"}
            onChange={() => updateFormData("existingData", "no")}
          />
          No
        </label>
      </div>

      {formData.existingData === "yes" && (
        <div className="data-type-container">
          <h6>Specify the data type:</h6>
          <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
            {dataTypes.map((type) => (
              <label
                key={type}
                style={{
                  border: formData.dataTypes.includes(type) ? "2px solid #00264d" : "1px solid #ccc",
                }}
              >
                <input
                  type="checkbox"
                  checked={formData.dataTypes.includes(type)}
                  onChange={() => handleCheckboxChange(type)}
                  style={{ marginRight: "10px" }}
                />
                {type}
              </label>
            ))}
          </div>
        </div>
      )}

      {formData.existingData === "yes" && (
        <div>
          <div style={{ marginTop: "20px" }}>
            <p>Are you interested in integrating with Open Data and other public sources?</p>
            <label>
              <input
                type="radio"
                name="openDataIntegration"
                value="yes"
                checked={formData.openDataIntegration === "yes"}
                onChange={() => updateFormData("openDataIntegration", "yes")}
              />
              Yes
            </label>
            <label>
              <input
                type="radio"
                name="openDataIntegration"
                value="no"
                checked={formData.openDataIntegration === "no"}
                onChange={() => updateFormData("openDataIntegration", "no")}
              />
              No
            </label>
          </div>

          {formData.openDataIntegration === "yes" && (
            <div style={{ marginTop: "20px" }}>
              <p>Select Open Data Sources:</p>
              <div style={{ display: "flex", flexWrap: "wrap", gap: "10px", flexDirection: "column" }}>
                {defaultOpenDataSources.map((source) => (
                  <label key={source} style={{ display: "flex", alignItems: "center" }}>
                    <input
                      type="checkbox"
                      checked={formData.openDataSources.includes(source)}
                      onChange={() => handleOpenDataSourceChange(source)}
                      style={{ marginRight: "10px" }}
                    />
                    {source}
                  </label>
                ))}
                {formData.openDataSources
                  .filter((source) => !defaultOpenDataSources.includes(source))
                  .map((source) => (
                    <label key={source} style={{ display: "flex", alignItems: "center" }}>
                      <input
                        type="checkbox"
                        checked={formData.openDataSources.includes(source)}
                        onChange={() => handleOpenDataSourceChange(source)}
                        style={{ marginRight: "10px" }}
                      />
                      {source}
                    </label>
                  ))}
              </div>

              <div style={{ marginTop: "20px", display: "flex", gap: "10px" }}>
                <input
                  type="text"
                  value={newSource}
                  onChange={(e) => setNewSource(e.target.value)}
                  placeholder="Add a new source"
                />
                <button type="button" onClick={handleAddNewSource} style={{ padding: "5px 10px" }}>
                  +
                </button>
              </div>
            </div>
          )}

          <div style={{ marginTop: "20px" }}>
            <p>Are you interested in selecting files from third-party databases?</p>
            <label>
              <input
                type="radio"
                name="thirdPartyFiles"
                value="yes"
                checked={formData.thirdPartyFiles === "yes"}
                onChange={() => updateFormData("thirdPartyFiles", "yes")}
              />
              Yes
            </label>
            <label>
              <input
                type="radio"
                name="thirdPartyFiles"
                value="no"
                checked={formData.thirdPartyFiles === "no"}
                onChange={() => updateFormData("thirdPartyFiles", "no")}
              />
              No
            </label>
          </div>
        </div>
      )}
    </div>
  );
}

export default StepExistingData;
