import React from "react";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import ContentWrapper from "../components/ContentWrapper";
import Activities from "../components/Activities";
//import Demos from '../components/Demos';
import AccountMenu from "../components/AccountMenu";
import MenuItem from "../components/MenuItem";
import { useNavigate } from "react-router-dom";

function Home() {
  const navigate = useNavigate();
  return (
    <div className="home">
      <Header />
      <div className="main-wrapper">
        <Sidebar />
        <div className="main-content">
          <div className="main-content-primary" style={{ padding: '36px 24px' }}>
            <h5>Projects</h5>
            <ContentWrapper>
              <Activities />
            </ContentWrapper>
            {/*<Demos />*/}
          </div>
          <div className="main-content-sidebar">
            <ContentWrapper>
              <AccountMenu />
            </ContentWrapper>
            <button className="new-request-btn" onClick={() => navigate(`/new-request`)}>
              + New Request
            </button>
            <ContentWrapper>
              <MenuItem name="Contact Us" />
              <MenuItem name="Support" />
              <MenuItem name="FAQ" />
            </ContentWrapper>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
