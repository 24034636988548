import React, { useState } from "react";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import StepAreaTime from "../components/requestSteps/StepAreaTime";
import StepObjective from "../components/requestSteps/StepObjective";
import StepActivitySelection from "../components/requestSteps/StepActivitySelection";
import StepExistingIssue from "../components/requestSteps/StepExistingIssue";
import StepExistingData from "../components/requestSteps/StepExistingData";
import StepConfirm from "../components/requestSteps/StepConfirm";
import ConfirmationPage from "../components/ConfirmationPage";
import { Stepper } from "react-form-stepper";
import { useNavigate } from "react-router-dom";
import { Button, CircularProgress } from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function NewRequest() {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [confirmationData, setConfirmationData] = useState(null);

  const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcGkiOiJ0aXRhbjQiLCJ1c2VyIjoiNjc1ODFmY2ZjY2EyMDllZWJiYTAyMzhjIn0.DfUi50dxZR68GmpVjunFc_vE8EXo3sSg1UbwmWs02Aw";

  const [currentStep, setCurrentStep] = useState(0);
  const [formData, setFormData] = useState({
    // Step 1: Activity & Request Selection
    activity: "", // Selected activity
    requestType: "",
    requestActivityType: "", // Either 'Assessment' or 'Monitoring'

    // Step 2: Area & Time
    areaOfInterestType: "",
    areaOfInterest: null,
    timeOfInterest: { from: "", to: "" }, // Start and end time
    recurrenceInterval: null,
    recurrenceType: "",

    // Step 3: Objective
    mainObjective: "", // E.g., 'Evaluate an existing structure'
    extraInfo: { structureType: "", file: null, description: "" },

    // Step 4: Existing Issue
    knownIssue: "no", // Either 'yes' or 'no'
    geohazards: [], // Array of selected geohazards
    structural: [], // Array of selected structural issues

    // Step 5: Existing Data
    existingData: "no", // Either 'yes' or 'no'
    dataTypes: [], // Array of selected data types
    openDataIntegration: "no", // Either 'yes' or 'no'
    openDataSources: [],
    thirdPartyFiles: "no", // Either 'yes' or 'no'

    // Final: Confirm
    requestPriority: "medium",
  });

  const steps = [
    { title: "Project & Request", component: StepActivitySelection },
    { title: "Area & Time", component: StepAreaTime },
    { title: "Objective", component: StepObjective },
    { title: "Existing Issue", component: StepExistingIssue },
    { title: "Existing Data", component: StepExistingData },
    { title: "Confirm", component: StepConfirm },
  ];

  const handleNext = () => {
    if (currentStep === 1) {
      if (!formData.areaOfInterest) {
        toast.error("Please set the Area of Interest before proceeding.");
        return;
      }
      const { from, to } = formData.timeOfInterest;
      if (!from || !to) {
        toast.error("Please set both 'From' and 'To' dates for Time of Interest.");
        return;
      }
      const fromDate = new Date(from);
      const toDate = new Date(to);
      if (fromDate >= toDate) {
        toast.error("'From' date must be earlier than 'To' date.");
        return;
      }
    }

    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      const response = await fetch("https://data-api.titan4.it/frontend/submit-request", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
        },
        body: JSON.stringify(formData),
      });
      if (response.ok) {
        const data = await response.json();
        setConfirmationData(data);
      } else {
        const errorData = await response.json();
        toast.error(errorData.error || "Submission failed. Please try again.");
      }
    } catch (error) {
      toast.error(`Error: ${error.message}`);
    } finally {
      setIsSubmitting(false);
    }
  };

  const updateFormData = (field, value) => {
    setFormData((prevFormData) => ({ ...prevFormData, [field]: value }));
  };

  const CurrentStepComponent = steps[currentStep].component;

  return (
    <div className="activity">
      <Header />
      <div className="main-wrapper">
        <Sidebar />
        <div className="main-content new-request-wrapper">
          <ToastContainer position="top-right" autoClose={5000} />
          { confirmationData ? (
            <ConfirmationPage confirmationData={confirmationData} />
          ) : (
            <div className="new-request">
              <h2>New Request</h2>
              <Stepper steps={steps.map((s) => ({ label: s.title }))}
                activeStep={currentStep}
                className='stepper'
                stepClassName='step'
                connectorStateColors={true} styleConfig={{
                  inactiveBgColor: '#C5CBD7',
                  activeBgColor: '#C5CBD7',
                  completedBgColor: '#010E2D',
                  size: '40px',
                  borderRadius: '100%',
                }} connectorStyleConfig={{
                  disabledColor: '#C5CBD7',
                  activeColor: '#010E2D',
                  completedColor: '#010E2D',
                  size: '3px',
                }}/>
              <div className="step-content">
                <CurrentStepComponent formData={formData} updateFormData={updateFormData} setCurrentStep={setCurrentStep} />
              </div>
            </div>
          )}
          <div className="navigation">
            <div className="navigation-left">
              <button className="link" onClick={() => navigate("/")} disabled={isSubmitting}>
                Back To Projects
              </button>
            </div>
            { !confirmationData && (
              <div className="navigation-right">
                <button className="round" onClick={handleBack} disabled={currentStep === 0 || isSubmitting}>
                  <i className="icon-chevron-left"></i>
                </button>
                {currentStep < steps.length - 1 ? (
                  <button className="round" onClick={handleNext} disabled={isSubmitting}>
                    <i className="icon-chevron-right"></i>
                  </button>
                ) : (
                  <Button variant="contained" color="primary" onClick={handleSubmit} disabled={isSubmitting}>
                    {isSubmitting ? <CircularProgress size={24} color="inherit" /> : "Submit"}
                  </Button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewRequest;
