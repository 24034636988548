import React, { useEffect, useState } from "react";

function StepObjective({ formData, updateFormData }) {
  const [isObjectiveContext, setIsObjectiveContext] = useState(false);
  const [selectedObjectives, setSelectedObjectives] = useState([]);

  useEffect(() => {
    setIsObjectiveContext(selectedObjectives.includes("context"));
  }, [formData, selectedObjectives]);

  const toggleCheckbox = (value) => {
    setSelectedObjectives((prev) =>
      prev.includes(value)
        ? prev.filter((item) => item !== value) // Deselect if already selected
        : [...prev, value]                     // Add if not selected
    );
  };

  return (
    <div className="objective-wrapper">
      <h3>Main Objective</h3>
      <div className="row">
        <div className="col-2">
          <h6>What is the main objective of your request?</h6>
          <label>
            <input
              type="checkbox"
              name="mainObjective"
              value="evaluate"
              checked={selectedObjectives.includes("evaluate")}
              onChange={() => toggleCheckbox("evaluate")}
            />
            Evaluate an existing structure
          </label>
          <label>
            <input
              type="checkbox"
              name="mainObjective"
              value="assess"
              checked={selectedObjectives.includes("assess")}
              onChange={() => toggleCheckbox("assess")}
            />
            Assess environmental impact
          </label>
          <label>
            <input
              type="checkbox"
              name="mainObjective"
              value="context"
              checked={selectedObjectives.includes("context")}
              onChange={() => toggleCheckbox("context")}
            />
            The environmental context
          </label>
        </div>
        <div className="col-2">
          <h6>Add Extra Information</h6>
          <select
            value={formData.extraInfo.structureType}
            disabled={isObjectiveContext}
            onChange={(e) =>
              updateFormData("extraInfo", {
                ...formData.extraInfo,
                structureType: e.target.value,
              })
            }
          >
            <option value="">Select the type of structure</option>
            <option value="ponti_viadotti">Ponti/viadotti</option>
            <option value="gallerie">Gallerie</option>
            <option value="tratto_stradale_rilevato">Tratto stradale in rilevato</option>
            <option value="tratto_stradale_trincea">Tratto stradale in trincea</option>
            <option value="opera_sostegno">Opera di sostegno</option>
            <option value="altro">Altro</option>
          </select>
          <input
            type="file"
            className="file-extra-info"
            disabled={isObjectiveContext}
            onChange={(e) =>
              updateFormData("extraInfo", {
                ...formData.extraInfo,
                file: e.target.files[0],
              })
            }
          />
          <textarea
            placeholder="Description (less than 100 words)"
            className="desc-extra-info"
            value={formData.extraInfo.description}
            disabled={isObjectiveContext}
            onChange={(e) =>
              updateFormData("extraInfo", {
                ...formData.extraInfo,
                description: e.target.value,
              })
            }
          />
        </div>
      </div>
    </div>
  );
}

export default StepObjective;
