import React, { useState, useEffect } from 'react';
import { client } from "../services/api";
import ActivityItem from './ActivityItem';
import ModalActivity from "./ModalActivity";

function Activities() {
  const [projects, setProjects] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    client.getProjects()
      .then((data) => setProjects(data))
      .catch((err) => console.error(err.message));
  }, []);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleDone = (newActivity) => {
  };

  return (
    <div className="activities">
      {projects.length === 0 && 'You have no available Projects.'}
      {projects.map((project) => (
        <div key={project.id}>
          <ActivityItem project={project} />
        </div>
      ))}
      <div className='section-bubble'>
        <button onClick={() => setIsModalOpen(true)}>+ Create a New Project</button>
        <ModalActivity isOpen={isModalOpen} onClose={handleCloseModal} onDone={handleDone}></ModalActivity>
      </div>
    </div>
  );
}

export default Activities;