import React from "react";
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
//import Status from '../images/status.svg'

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

function ActivityItem({ project }) {
  return (
    <div className="activity-item">
      <div className="section-bubble">
        <h5 style={{ marginTop: 0 }}>{project.name}</h5>
        <div className="activity-info">
          <img className="thumbnail" src={project.thumbnail} alt={project.name} />
          <div className="info-list">
            <div>
              <span>Area of Interest:</span> 3 km²
            </div>
            <div>
              <span>Type of Analysis:</span> Monitoring
            </div>
            <div>
              <span>Data Source:</span> Sentinel1-ALOS
            </div>
          </div>
        </div>
      </div>
      <div className="section-bubble">
        <h6>Ongoing Requests</h6>
        <div className="request-item">
          <div>Request 01</div>
          <div>Start: 02/12/2023</div>
          <div>End: 02/12/2023</div>
          <LinearProgressWithLabel value={50} />
          <a href="/">details</a>
        </div>
        <div className="request-item">
          <div>Request 02</div>
          <div>Start: 02/12/2023</div>
          <div>End: 02/12/2023</div>
          <LinearProgressWithLabel value={80} />
          <a href="/">details</a>
        </div>
      </div>
      <div className="section-bubble">
        <h6>
          Notifications <span style={{ color: "red" }}>(1)</span>
        </h6>
        <div className="notification-item">
          <div className="icon red"></div>
          <div className="data">
            <div>12/09/2024</div>
            <div>Data Upload Failed</div>
          </div>
          <a href="/">More details</a>
        </div>
        <div className="notification-item">
          <div className="icon yellow"></div>
          <div className="data">
            <div>27/07/2024</div>
            <div>Review Required</div>
          </div>
          <a href="/">More details</a>
        </div>
        <div className="notification-item">
          <div className="icon green"></div>
          <div className="data">
            <div>05/04/2024</div>
            <div>Short Description</div>
          </div>
          <a href="/">More details</a>
        </div>
      </div>
    </div>
  );
}

export default ActivityItem;
