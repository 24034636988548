import React from "react";
import priorityLow from "../../images/priority_low.png";
import priorityMedium from "../../images/priority_medium.png";
import priorityHigh from "../../images/priority_high.png";

function StepConfirm({ formData, updateFormData, setCurrentStep }) {
  const requestPriority = ["low", "medium", "high"];

  return (
    <div className="step-confirm">
      <h3>Request Priority</h3>
      <div className="request-priority-wrapper">
        {requestPriority.map((option, index) => (
          <div
            key={index}
            onClick={() => updateFormData("requestPriority", option)}
            className={"request-priority-container" + (formData.requestPriority === option ? " active" : "")}
          >
            <img
              src={
                option === "low"
                  ? priorityLow
                  : option === "medium"
                  ? priorityMedium
                  : priorityHigh
              }
              alt={option}
              className="request-priority-img"
            />
            <div className="request-priority-text">{option}</div>
          </div>
        ))}
      </div>

      {/* Summary */}
      <h4>Summary</h4>
      <div className="summary">
        <div className="summary-row">
          <span>
            <strong>Area:</strong> {formData.areaOfInterestType || "Not specified"}
          </span>
          <span>
            <strong>Time:</strong>{" "}
            {`${formData.timeOfInterest?.from || "Not specified"} to ${formData.timeOfInterest?.to || "Not specified"}`}
          </span>
          <button onClick={() => setCurrentStep(1)} className="edit-button">
            <i className="icon-edit"></i>
          </button>
        </div>
        <div className="summary-row">
          <span>
            <strong>Main Objective:</strong> {formData.mainObjective || "Not specified"}
          </span>
          <button onClick={() => setCurrentStep(2)} className="edit-button">
            <i className="icon-edit"></i>
          </button>
        </div>
        <div className="summary-row">
          <span>
            <strong>Existing Issue:</strong> {formData.geohazards.join(", ") || "None"}
          </span>
          <button onClick={() => setCurrentStep(3)} className="edit-button">
            <i className="icon-edit"></i>
          </button>
        </div>
        <div className="summary-row">
          <span>
            <strong>Existing Data:</strong> {formData.dataTypes.join(", ") || "None"}
          </span>
          <button onClick={() => setCurrentStep(4)} className="edit-button">
            <i className="icon-edit"></i>
          </button>
        </div>
      </div>
      <br /><br />
      <h4>Advanced Details</h4>
      <div className="summary">
        <div className="summary-row" style={{ display: 'block' }}>
          <span><strong>Application:</strong> </span><br />
          <span><strong>Direction:</strong> </span><br />
          <span><strong>Band and Resolution:</strong> </span>
          <button onClick={() => null} className="edit-button" style={{
            float: 'right',
            position: 'relative',
            top: '-15px' }}
          >
            <i className="icon-edit"></i>
          </button>
        </div>
      </div>
    </div>
  );
}

export default StepConfirm;
