import React, { useState, useEffect, useRef, useCallback } from "react";
import Map, { Source, Layer } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import bbox from "@turf/bbox";
import center from "@turf/center";
import area from "@turf/area";
import ModalDrawMap from "../ModalDrawMap";
import { Tooltip } from "@mui/material";

const MAPBOX_TOKEN = 'pk.eyJ1IjoiZnJ4MDgiLCJhIjoiY2puOGtrczNnNmh4cDNrbngwaXQ1Nm42NyJ9.TTsHdLQig2CLRi9wtRviRg';

function StepAreaTime({ formData, updateFormData }) {
  const [isDrawModalOpen, setIsDrawModalOpen] = useState(false);
  const mapRef = useRef();

  const requestActivityTypes = ["Assessment", "Monitoring"];

  const handleCheckboxChange = (type) => {
    const updatedRequestType = formData.requestActivityType === type ? "" : type;
    updateFormData("requestActivityType", updatedRequestType);
  };

  const [geoJson, setGeoJson] = useState(null);
  const [geometryInfo, setGeometryInfo] = useState({ center: null, area: null });
  const [viewport, setViewport] = useState({
    latitude: 41.8719,
    longitude: 12.56,
    zoom: 10,
  });

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const content = JSON.parse(e.target.result);
        setGeoJson(content);
        calculateGeometryInfo(content);
        updateFormData("areaOfInterest", content);
      };
      reader.readAsText(file);
    }
  };

  const calculateGeometryInfo = (geoJson) => {
    const geoCenter = center(geoJson);
    const [lng, lat] = geoCenter.geometry.coordinates;

    const geoArea = area(geoJson) / 1_000_000;

    setGeometryInfo({ center: { lat, lng }, area: geoArea.toFixed(2) });
  };

  const geoJsonLayer = {
    id: 'geojson-layer',
    type: 'fill',
    paint: {
      'fill-color': '#010E2D',
      'fill-opacity': 0.5,
    },
  };

  const fitGeometry = useCallback(() => {
    if (geoJson && mapRef.current) {
      const bounds = bbox(geoJson);

      mapRef.current.fitBounds(bounds, { padding: 20 });
    }
  }, [geoJson]);

  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.resize();
    }
    fitGeometry();
  }, [formData.areaOfInterest, fitGeometry]);

  useEffect(() => {
    fitGeometry();
  }, [geoJson, fitGeometry, updateFormData]);

  const handleDrawSelection = () => {
    setGeoJson(null);
    setIsDrawModalOpen(true);
  };

  const handleDrawDone = (drawnData) => {
    if (isValidGeoJSON(drawnData)) {
      setGeoJson(drawnData);
      calculateGeometryInfo(drawnData);
      updateFormData("areaOfInterest", drawnData);
    }
    setIsDrawModalOpen(false);
  };

  const isScheduleButtonEnabled = () => {
    const { from, to } = formData.timeOfInterest;
    if (!from || !to) return false;

    const fromDate = new Date(from);
    const toDate = new Date(to);
    const now = new Date();

    return fromDate < toDate && toDate > now;
  };

  const isValidGeoJSON = (geojson) => {
    if (!geojson || geojson.type !== "FeatureCollection" || !Array.isArray(geojson.features)) {
      return false;
    }
    for (const feature of geojson.features) {
      if (feature.type !== "Feature" || !feature.geometry) {
        return false;
      }
      const { type, coordinates } = feature.geometry;
      if (type !== "Polygon") {
        return false;
      }
      if (!isValidPolygonCoordinates(coordinates)) {
        return false;
      }
    }
    return true;
  };
  
  const isValidPolygonCoordinates = (coordinates) => {
    if (!Array.isArray(coordinates) || coordinates.length === 0) {
      return false;
    }
    return coordinates.every((ring) => {
      if (!Array.isArray(ring) || ring.length < 4) {
        return false;
      }
      if (!ring.every((position) => isValidPosition(position))) {
        return false;
      }
      const firstPosition = ring[0];
      const lastPosition = ring[ring.length - 1];
      return (
        firstPosition[0] === lastPosition[0] &&
        firstPosition[1] === lastPosition[1]
      );
    });
  };
  
  const isValidPosition = (position) => {
    return (
      Array.isArray(position) &&
      position.length === 2 &&
      typeof position[0] === "number" &&
      typeof position[1] === "number"
    );
  };

  return (
    <div>
      <h3>Area of Interest</h3>
      <div>
        <div className="row">
          <div className="col-4">
            <label className="area-container">
              <input
                type="radio"
                name="areaOfInterestType"
                value="file"
                checked={formData.areaOfInterestType === "file"}
                onChange={() => updateFormData("areaOfInterestType", "file")}
              />
              Insert File .geojson
            </label>
            {formData.areaOfInterestType === "file" && (
              <input className="area-container" type="file" accept=".geojson" onChange={handleFileUpload} />
            )}
            <label className="area-container">
              <input
                type="radio"
                name="areaOfInterestType"
                value="draw"
                checked={formData.areaOfInterestType === "draw"}
                onChange={() => {
                  updateFormData("areaOfInterestType", "draw");
                  handleDrawSelection();
                }}
                onClick={() => {
                  updateFormData("areaOfInterestType", "draw");
                  handleDrawSelection();
                }}
              />
              Draw on map
            </label>
          </div>
          <div style={{ width: '75%', height: '300px' }}>
            <Map
              ref={mapRef}
              className="area-map"
              mapboxAccessToken={MAPBOX_TOKEN}
              initialViewState={viewport}
              mapStyle="mapbox://styles/mapbox/streets-v11"
              onMove={(evt) => setViewport(evt.viewState)}
            >
              {geoJson && (
                <Source id="geojson-source" type="geojson" data={geoJson}>
                  <Layer {...geoJsonLayer} />
                </Source>
              )}
            </Map>

            <div className="geometry-info">
              <span>Selected area: {geometryInfo.area && `${geometryInfo.area} km²`}</span>
              { geometryInfo.center?.lat && <span>{geometryInfo.center?.lat?.toFixed(5)}, {geometryInfo.center?.lng?.toFixed(5)}</span>}
            </div>
            <ModalDrawMap isOpen={isDrawModalOpen} onClose={() => setIsDrawModalOpen(false)} onDone={handleDrawDone} />
          </div>
        </div>
      </div>

      <div className="request-type">
        <div className="data-type-container">
          <h6>Activity Type:</h6>
          <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
            {requestActivityTypes.map((type) => (
              <label key={type} className={`checkbox ${formData.requestActivityType === type ? "selected" : ""}`}>
                <input type="checkbox" checked={formData.requestActivityType === type} onChange={() => handleCheckboxChange(type)} />
                {type}
              </label>
            ))}
          </div>
        </div>
      </div>

      <h3>Time of Interest</h3>
      <div className="wrapper-time">
        <label htmlFor="from-date">From</label>
        <input
          type="date"
          value={formData.timeOfInterest.from}
          onChange={(e) =>
            updateFormData("timeOfInterest", {
              ...formData.timeOfInterest,
              from: e.target.value,
            })
          }
        />
        <label htmlFor="to-date">To</label>
        <input
          type="date"
          value={formData.timeOfInterest.to}
          onChange={(e) =>
            updateFormData("timeOfInterest", {
              ...formData.timeOfInterest,
              to: e.target.value,
            })
          }
        />
      </div>
      <Tooltip
        title={
          !isScheduleButtonEnabled()
            ? "Requirements:\n1. Both 'From' and 'To' dates must be set.\n2. 'From' date must be before 'To' date.\n3. 'To' date must be in the future."
            : ""
        }
        placement="top"
        arrow>
        <div className="wrapper-time" style={{ width: 'fit-content' }}>
          <label htmlFor="from-date">Every</label>
          <input
            type="number"
            value={formData.recurrenceInterval}
            style={{ width: 'auto' }}
            disabled={!isScheduleButtonEnabled()}
            min="1"
            onChange={(e) => updateFormData('recurrenceInterval', e.target.value)}
          />
          <select
            value={formData.recurrenceType}
            style={{ width: 'auto' }}
            onChange={(e) => updateFormData('recurrenceType', e.target.value)}
            disabled={!isScheduleButtonEnabled()}>
            <option value="Days">Days</option>
            <option value="Weeks">Weeks</option>
            <option value="Months">Months</option>
          </select>
          <span>Calculate</span>
        </div>
      </Tooltip>
    </div>
  );
}

export default StepAreaTime;
