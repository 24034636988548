import React, { useState } from "react";
import Dropdown from "./Dropdown";
import Logo from "../images/logo.svg";
import User from "../images/user.png";
import Switch from "react-js-switch";

function Header() {
  const [activities, setActivities] = useState([
    { name: "Project Name 1", checked: true },
    { name: "Project Name 2", checked: false },
    { name: "Project Name 3", checked: false },
  ]);

  const toggleActivity = (index, checked) => {
    activities[index].checked = !activities[index].checked;
    setActivities([...activities]);
  };

  return (
    <header className="header">
      <img className="logo" src={Logo} alt="Titan4 Logo" />
      <div style={{ display: "flex" }}>
        <Dropdown title="Switch Project" inverted={true}>
          <div className="switch-activity-dropdown">
            {activities.map((activity, index) => (
              <div className="switch-activity-item" key={index}>
                <div>
                  <div className="activity-title">{activity.name}</div>
                  <div className="activity-date">10/10/2024</div>
                </div>
                <Switch
                  backgroundColor={{ on: "#f0941e", off: "#2F3B57" }}
                  borderColor={{ on: "transparent", off: "transparent" }}
                  onChange={(e) => toggleActivity(index, e)}
                  value={activity.checked}
                />
              </div>
            ))}
          </div>
        </Dropdown>
        <Dropdown title="Notificatons" inverted={true}>
          <div className="notification-dropdown">
            {Array(4)
              .fill()
              .map((_, index) => (
                <div className="notification-item" key={index}>
                  <div>
                    <div className="notification-title">Notification Description</div>
                    <div className="notification-date">10/10/2024</div>
                  </div>
                  <i className="icon-external-link"></i>
                </div>
              ))}
          </div>
        </Dropdown>
        <Dropdown title="Settings" inverted={true}>
          <div className="setting-dropdown">
            <div>Mode</div>
            <label>
              <input type="radio" name="mode" defaultChecked /> Light
            </label>
            <label>
              <input type="radio" name="mode" /> Dark
            </label>
            <label>
              <input type="radio" name="mode" /> Default
            </label>
            <hr />
            <div>Units</div>
            <label>
              <input type="radio" name="units" defaultChecked /> km²
            </label>
            <label>
              <input type="radio" name="units" /> mi²
            </label>
            <hr />
            <div>TimeSeries Viewer</div>
            <label>
              <input type="radio" name="viewer" defaultChecked /> Basic
            </label>
            <label>
              <input type="radio" name="viewer" /> Advanced
            </label>
          </div>
        </Dropdown>
        <img src={User} alt="User" />
      </div>
    </header>
  );
}

export default Header;
