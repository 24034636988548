import React, { useState, useEffect } from "react";
import CheckboxTree from "react-checkbox-tree";
import "react-checkbox-tree/lib/react-checkbox-tree.css";

const geohazards_tree = [
  {
    value: "landslide",
    label: "Landslide",
    children: [
      {
        value: "landslide_speed",
        label: "Speed",
        children: [
          { value: "landslide_speed_extemely_rapid", label: "Extremely rapid (5 m/s)" },
          { value: "landslide_speed_very_rapid", label: "Very rapid (3 m/min)" },
          { value: "landslide_speed_rapid", label: "Rapid (1.8 m/h)" },
          { value: "landslide_speed_moderate", label: "Moderate (13 m/month)" },
          { value: "landslide_speed_slow", label: "Slow (1.6 m/year)" },
          { value: "landslide_speed_very_slow", label: "Very slow (16 mm/year)" },
          { value: "landslide_speed_extemely_slow", label: "Extremely slow (<16 mm/year)" },
        ],
      },
      {
        value: "landslide_activity_status",
        label: "Activity status",
        children: [
          { value: "landslide_activity_status_active", label: "Active" },
          { value: "landslide_activity_status_suspended", label: "Suspended" },
          { value: "landslide_activity_status_inactive", label: "Inactive" },
          { value: "landslide_activity_status_dormant", label: "Dormant" },
        ],
      },
      {
        value: "landslide_movement_type",
        label: "Movement type",
        children: [
          { value: "landslide_movement_type_fall", label: "Fall" },
          { value: "landslide_movement_type_topple", label: "Topple" },
          { value: "landslide_movement_type_slide", label: "Slide" },
          { value: "landslide_movement_type_spread", label: "Spread" },
          { value: "landslide_movement_type_flow", label: "Flow" },
        ],
      },
    ],
  },
  {
    value: "coastal_erosion",
    label: "Coastal Erosion",
    children: [
      {
        value: "coastal_erosion_speed",
        label: "Speed",
        children: [
          { value: "coastal_erosion_speed_weak_erosion", label: "Weak erosion (between -0.5 and -1.0 m/yr)" },
          { value: "coastal_erosion_speed_intense_erosion", label: "Intense erosion (between -1.0 and -3.0 m/yr)" },
          { value: "coastal_erosion_speed_severe_erosion", label: "Severe erosion (between -3.0 and -5.0 m/yr)" },
          { value: "coastal_erosion_speed_extreme_erosion", label: "Extreme erosion (greater than -5.0 m/yr)" },
        ],
      },
      {
        value: "coastal_erosion_activity_status",
        label: "Activity status",
        children: [
          { value: "coastal_erosion_activity_status_active", label: "Active" },
          { value: "coastal_erosion_activity_status_inactive", label: "Inactive" },
        ],
      },
      {
        value: "coastal_erosion_movement_type",
        label: "Movement type",
        children: [
          { value: "coastal_erosion_activity_status_natural", label: "Natural" },
          { value: "coastal_erosion_activity_status_man_caused", label: "Man-caused" },
        ],
      },
    ],
  },
  {
    value: "flooding",
    label: "Flooding",
    children: [
      {
        value: "flooding_type",
        label: "Type",
        children: [
          { value: "flooding_type_flash_floods", label: "Flash floods" },
          { value: "flooding_type_slow_floods", label: "Slow floods" },
          { value: "flooding_type_seasonal_floods", label: "Seasonal floods" },
        ],
      },
    ],
  },
  {
    value: "subsidence",
    label: "Subsidence",
    children: [
      {
        value: "subsidence_cause",
        label: "Cause",
        children: [
          { value: "subsidence_cause_natural", label: "Natural" }, // just a section title
          { value: "subsidence_cause_bradyseism", label: "Bradyseism" },
          { value: "subsidence_cause_sinkhole", label: "Sinkhole" },
          { value: "subsidence_cause_others", label: "Others" },
          { value: "subsidence_cause_man_caused", label: "Man-caused" }, // just a section title
          { value: "subsidence_cause_Tunnel excavation", label: "Tunnel excavation" },
          { value: "subsidence_cause_water_extraction", label: "Water extraction" },
          { value: "subsidence_cause_oil_gas_production", label: "Oil and Gas production" },
        ],
      },
    ],
  },
  {
    value: "liquefaction",
    label: "Liquefaction",
    children: [
      {
        value: "liquefaction_cause",
        label: "Cause",
        children: [
          { value: "liquefaction_cause_static", label: "Static" },
          { value: "liquefaction_cause_earthquake_induced", label: "Earthquake-induced" },
        ],
      },
    ],
  },
  {
    value: "seismic_activity",
    label: "Seismic activity",
    children: [],
  },
];

const structural_tree = [
  {
    value: "material_degradation",
    label: "Material Degradation (concrete corrosion, structural steel degradation, etc.)",
  },
  {
    value: "foundation_settling",
    label: "Foundation Settling (ground instability causing uneven settling)",
  },
  {
    value: "cracks_fractures",
    label: "Cracks and Fractures (due to excessive loads or thermal stresses)",
  },
  {
    value: "vibrations_impacts",
    label: "Vibrations and Impacts (damage from heavy traffic or seismic activities)",
  },
  {
    value: "damage_from_external_events",
    label: "Damage from External Events (impacts from landslides or flooding)",
  },
  {
    value: "stability_structural_failure_issues",
    label: "Stability and Structural Failure Issues",
  },
];

function StepExistingIssue({ formData, updateFormData }) {
  const [checkedGeohazards, setCheckedGeohazards] = useState(formData.geohazards || []);
  const [expandedGeohazards, setExpandedGeohazards] = useState([]);
  const [checkedStructural, setCheckedStructural] = useState(formData.structural || []);
  const [expandedStructural, setExpandedStructural] = useState([]);

  useEffect(() => {
    setCheckedGeohazards(formData.geohazards || []);
    setCheckedStructural(formData.structural || []);
  }, [formData]);

  const handleGeohazardsCheck = (checked) => {
    setCheckedGeohazards(checked);
    updateFormData("geohazards", checked);
  };

  const handleStructuralCheck = (checked) => {
    setCheckedStructural(checked);
    updateFormData("structural", checked);
  };

  return (
    <div>
      <h3>Existing Issue</h3>
      <div>
        <p>Is there a known issue/hazard in the area?</p>
        <label>
          <input
            type="radio"
            name="knownIssue"
            value="yes"
            checked={formData.knownIssue === "yes"}
            onChange={() => updateFormData("knownIssue", "yes")}
          />
          Yes
        </label>
        <label>
          <input
            type="radio"
            name="knownIssue"
            value="no"
            checked={formData.knownIssue === "no"}
            onChange={() => updateFormData("knownIssue", "no")}
          />
          No
        </label>
      </div>

      {formData.knownIssue === "yes" && (
        <div className="row existing-issue">
          <div className="col-1">
            <p>Specify the characteristic of the issue:</p>
          </div>
          <div className="col-2">
            <h6>Geohazards</h6>
            <CheckboxTree
              nodes={geohazards_tree}
              checked={checkedGeohazards}
              expanded={expandedGeohazards}
              onCheck={handleGeohazardsCheck}
              onExpand={(expanded) => setExpandedGeohazards(expanded)}
              onlyLeafCheckboxes={true}
            />
          </div>

          <div className="col-2">
            <h6>Structural</h6>
            <CheckboxTree
              nodes={structural_tree}
              checked={checkedStructural}
              expanded={expandedStructural}
              onCheck={handleStructuralCheck}
              onExpand={(expanded) => setExpandedStructural(expanded)}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default StepExistingIssue;
