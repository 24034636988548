import axios from 'axios';

import ActivityThumb1 from '../images/thumbnails/activity-thumb1.png'
//import ActivityThumb2 from '../images/thumbnails/activity-thumb2.png'
//import ActivityThumb3 from '../images/thumbnails/activity-thumb3.png'
//import DemoThumb1 from '../images/thumbnails/demo-thumb1.png'
//import DemoThumb2 from '../images/thumbnails/demo-thumb2.png'
//import DemoThumb3 from '../images/thumbnails/demo-thumb3.png'
//import DemoThumb4 from '../images/thumbnails/demo-thumb3.png'

export const create = (baseURL = process.env.REACT_APP_URL_API) => {
  const api = axios.create({
    baseURL,
    headers: {
    },
    timeout: 60000,
  });

  const getProjects = () => {
    //return api.get(`/api/projcts`);
    return Promise.resolve([{
      'id': 1,
      'name': 'Project Name 1',
      'lastOpened': '17 hours',
      'private': true,
      'thumbnail': ActivityThumb1,
    }/*,
    {
      'id': 2,
      'name': 'Project Name 2',
      'lastOpened': '2 days',
      'private': true,
      'thumbnail': ActivityThumb2,
    },
    {
      'id': 3,
      'name': 'Project Name 3',
      'lastOpened': '6 days',
      'private': false,
      'thumbnail': ActivityThumb3,
    }*/]);
  };

  const getDemos = () => {
    return api.get(`/api/demos`);
  };

  const getRequests = () => {
    return api.get(`/api/requests`);
  };

  return {
    getProjects,
    getDemos,
    getRequests,
    api,
  };
}

export const client = create();
