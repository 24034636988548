import React, { useState } from "react";
import bgScope from "../../images/bg-scope.png";
import ModalActivity from "../ModalActivity";

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

function StepActivitySelection({ formData, updateFormData }) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [activities, setActivities] = useState([
    { id: '0', activityName: 'Project 1', members: [] },
    { id: '1', activityName: 'Project 2', members: [] },
    { id: '2', activityName: 'Project 3', members: [] },
  ]);
  const requestOptions = [
    "Land and Infrastructure Assessment and Monitoring",
    "Classification and Change Detection",
    "Third Party Interference Detection",
    "Water Resource Management",
    "Land Use and Land Cover",
    "Disaster Response & Emergency Management",
  ];

  const handleChange = (e) => {
    const selectedValue = e.target.value;
    if (selectedValue === 'create-new') {
      setIsModalOpen(true);
    } else {
      updateFormData('activity', selectedValue);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleDone = (newActivity) => {
    const activityWithId = { ...newActivity, id: Math.random().toString(36).substring(2, 10) };
    setActivities((prev) => [...prev, activityWithId]);
    updateFormData('activity', activityWithId.id);
  };

  return (
    <div>
      <h3>Project Selection</h3>
      <Box sx={{ minWidth: 120 }}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Select a Project</InputLabel>
          <Select
            value={formData.activity}
            label="Select a Project"
            onChange={handleChange}
          >
            {activities.map((activity, index) => (
              <MenuItem key={index} value={activity.id}>
                {activity.activityName}
              </MenuItem>
            ))}
            <MenuItem value="create-new">+ Create project</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <ModalActivity isOpen={isModalOpen} onClose={handleCloseModal} onDone={handleDone}></ModalActivity>

      <h3>Activities Scope</h3>
      <div className="request-scope-wrapper">
        {requestOptions.map((option, index) => (
          <div
            key={index}
            onClick={() => updateFormData("requestType", option)}
            className={"request-scope-container" + (formData.requestType === option ? " active" : "")}
          >
            <img
              src={bgScope} // Replace with real image
              alt={option}
              style={{ width: "100%", height: "100px", objectFit: "cover" }}
            />
            <div className="request-scope-text">{option}</div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default StepActivitySelection;
